<template>
	<v-container id="user-profile" fluid tag="section">
		<v-row justify="center">
			<v-col cols="12" md="12">
				<!--<base-material-card
					class="v-card-profile"
					avatar="/img/acara_logo_2.png"
				>-->
				<v-card class="v-card-profile">
					<v-card-title
						class="text-center"
						style="position: relative; bottom: 80px"
					>
						<v-avatar size="150" class="mx-auto">
							<img :src="avatarSrc" alt="Avatar" />
							<v-btn
								small
								icon
								@click="triggerFileInput"
								:disabled="userDisabled"
								style="
									position: absolute;
									bottom: 10px;
									right: 10px;
									background-color: rgba(1, 31, 91, 0.7);
								"
							>
								<v-icon>mdi-camera</v-icon>
							</v-btn>
						</v-avatar>
						<input
							type="file"
							ref="imageUploadInput"
							@change="handleImageUpload"
							accept="image/*"
							style="display: none"
						/>
					</v-card-title>

					<v-row style="position: relative; top: -70px">
						<v-col cols="12" md="12" sm="12" class="py-0 mx-auto text-center">
							<div class="text-h2 font-weight-medium text-primary2">
								{{ editedItem.datos_usuario.username }}
							</div>
						</v-col>
					</v-row>

					<v-card-text
						class="text-center"
						style="position: relative; top: -60px; bottom: 20px"
					>
						<v-form>
							<v-row class="pb-3">
								<v-col cols="12" md="12" sm="12" class="py-0">
									<v-toolbar dark color="primary" class="mt-4">
										<v-toolbar-title>Mis Datos</v-toolbar-title>
									</v-toolbar>

									<v-card-text
										class="elevation-1 pb-2"
										style="background: #fff"
									>
										<v-row>
											<v-col cols="12" md="4" sm="12" class="py-0">
												<v-text-field
													v-model="editedItem.datos_usuario.firstName"
													label="Nombre"
													:rules="[$rulesRequerido, $rulesAlfaNum, $rulesMax50]"
													:disabled="userDisabled"
												></v-text-field>
											</v-col>
											<v-col cols="12" md="3" sm="12" class="py-0">
												<v-text-field
													v-model="editedItem.datos_usuario.lastName"
													label="Apellido"
													:rules="[$rulesRequerido, $rulesAlfaNum, $rulesMax50]"
													:disabled="userDisabled"
												></v-text-field>
											</v-col>
											<v-col cols="12" md="2" sm="12" class="py-0">
												<v-autocomplete
													v-model="
														editedItem.datos_usuario.attributes.tipo_documento
													"
													:items="identifiertypes"
													item-text="name"
													item-value="id"
													label="Tipo Documento"
													:rules="[$rulesRequerido]"
													:disabled="userDisabled"
												></v-autocomplete>
											</v-col>
											<v-col cols="12" md="3" sm="12" class="py-0">
												<v-text-field
													v-model="
														editedItem.datos_usuario.attributes.documento
													"
													label="Documento"
													class="input-h1"
													:rules="[$rulesRequerido, $rulesAlfaNum]"
													maxlength="9"
													:disabled="userDisabled"
												></v-text-field>
											</v-col>

											<v-col cols="12" md="4" sm="12" class="py-0">
												<v-text-field
													v-model="editedItem.datos_usuario.attributes.telefono"
													label="Telefono"
													:rules="[$rulesRequerido, $rulesTelefono]"
													maxlength="20"
													:disabled="userDisabled"
												></v-text-field>
											</v-col>
											<v-col cols="12" md="4" sm="12" class="py-0">
												<v-text-field
													v-model="editedItem.datos_usuario.attributes.celular"
													label="Celular"
													:rules="[$rulesRequerido, $rulesTelefono]"
													maxlength="20"
													:disabled="userDisabled"
												></v-text-field>
											</v-col>
											<v-col cols="12" md="4" sm="12" class="py-0">
												<v-text-field
													v-model="editedItem.datos_usuario.email"
													label="Correo"
													:rules="[$rulesRequerido, $rulesMail]"
													:disabled="userDisabled"
												></v-text-field>
											</v-col>
											<v-col cols="12" class="text-right">
												<v-btn
													color="primary"
													outlined
													class="mr-2"
													@click="estadoEdicion"
												>
													<v-icon class="px-1">{{ userBtnEdicionImg }}</v-icon>
													{{ userBtnEdicion }}</v-btn
												>
												<v-btn
													color="primary"
													class="mr-0"
													:disabled="userDisabled"
													@click="actualizarDatosUsuarios(false)"
												>
													Guardar</v-btn
												>
											</v-col>
										</v-row>
									</v-card-text>
								</v-col>
							</v-row>

							<v-row class="pb-3">
								<v-col cols="12" md="12" sm="12" class="py-0">
									<v-toolbar dark color="primary" class="mt-4">
										<v-toolbar-title>Datos de la entidad</v-toolbar-title>
									</v-toolbar>

									<v-card-text
										class="elevation-1 pb-2"
										style="background: #fff"
									>
										<v-row>
											<v-col>
												<v-row>
													<v-col
														cols="12"
														md="6"
														sm="12"
														class="pa-0 pl-4 pt-4 pr-4"
													>
														<v-text-field
															v-model="editedItem.datos_entidad.cuit"
															label="CUIT/CUIL"
															hint="Ingrese solo números"
															maxLength="13"
															class="input-h1"
															prepend-inner-icon="mdi-card-account-details"
															:rules="[$rulesRequerido]"
															:disabled="1 == 1"
														></v-text-field>
													</v-col>
													<v-col
														cols="12"
														md="6"
														sm="12"
														class="pa-0 pl-4 pt-4 pr-4"
													>
														<v-text-field
															v-model="editedItem.datos_entidad.nombre"
															label="Razón Social"
															class="input-h1"
															prepend-inner-icon="mdi-card-account-details"
															:rules="[$rulesRequerido]"
															:disabled="1 == 1"
														></v-text-field>
													</v-col>
													<v-col cols="12" md="6" sm="12" class="py-0">
														<v-text-field
															v-model="
																editedItem.datos_entidad.datos.usuario_entidad
															"
															label="Usuario Entidad"
															:rules="[$rulesRequerido, $rulesAlfaNum]"
															filled
															style="margin-top: 7.5px"
															:disabled="1 == 1"
														></v-text-field>
													</v-col>
													<v-col
														cols="12"
														md="6"
														sm="12"
														class="pa-0 pl-4 pt-4 pr-4"
													>
														<v-text-field
															v-model="
																editedItem.datos_entidad.datos.nombre_entidad
															"
															label="Nombre Entidad"
															:rules="[$rulesRequerido, $rulesAlfaNum]"
															:disabled="1 == 1"
														></v-text-field>
													</v-col>

													<v-col cols="12" md="6" sm="12" class="py-0">
														<v-text-field
															v-model="editedItem.datos_entidad.datos.telefono"
															label="Telefono"
															:rules="[$rulesRequerido, $rulesTelefono]"
															maxlength="20"
															:disabled="entidadDisabled"
														></v-text-field>
													</v-col>
													<v-col cols="12" md="6" sm="12" class="py-0">
														<v-text-field
															v-model="editedItem.datos_entidad.datos.email"
															label="Correo"
															:rules="[$rulesRequerido, $rulesMail]"
															:disabled="entidadDisabled"
														></v-text-field>
													</v-col>
													<v-col cols="12" md="4" sm="12" class="py-0">
														<v-autocomplete
															:items="provincias"
															item-value="id"
															item-text="nombre"
															label="Provincia"
															v-model="editedItem.datos_entidad.datos.provincia"
															:rules="[$rulesRequerido]"
															@change="
																getPartidos(
																	editedItem.datos_entidad.datos.provincia.id
																)
															"
															return-object
															:disabled="entidadDisabled"
														></v-autocomplete
													></v-col>
													<v-col cols="12" md="4" sm="12" class="py-0">
														<v-autocomplete
															:items="partidos"
															item-value="id"
															item-text="nombre"
															label="Partido"
															v-model="editedItem.datos_entidad.datos.partido"
															:rules="[$rulesRequerido]"
															@change="
																getCiudades(
																	editedItem.datos_entidad.datos.partido.id
																)
															"
															return-object
															:disabled="entidadDisabled"
														></v-autocomplete>
													</v-col>

													<v-col cols="12" md="4" sm="12" class="py-0">
														<v-autocomplete
															:items="ciudades"
															item-value="id"
															item-text="nombre"
															label="Ciudad"
															v-model="editedItem.datos_entidad.datos.ciudad"
															:rules="[$rulesRequerido]"
															return-object
															:disabled="entidadDisabled"
														></v-autocomplete>
													</v-col>
												</v-row>
												<v-row>
													<v-col cols="12" md="8" sm="12" class="py-0">
														<v-text-field
															v-model="editedItem.datos_entidad.datos.calle"
															label="Calle"
															:rules="[$rulesRequerido, $rulesMax50]"
															:disabled="entidadDisabled"
														></v-text-field>
													</v-col>
													<v-col cols="12" md="4" sm="12" class="py-0">
														<v-text-field
															v-model="editedItem.datos_entidad.datos.numero"
															label="Numero"
															:rules="[
																$rulesRequerido,
																$rulesEnterosPositivos,
																$rulesMax6,
															]"
															:disabled="entidadDisabled"
														></v-text-field>
													</v-col>
												</v-row>
												<v-row>
													<v-col cols="12" md="4" sm="12" class="py-0">
														<v-text-field
															v-model="editedItem.datos_entidad.datos.piso"
															label="Piso"
															:rules="[$rulesEnterosPositivos, $rulesMax6]"
															:disabled="entidadDisabled"
														></v-text-field>
													</v-col>
													<v-col cols="12" md="4" sm="12" class="py-0">
														<v-text-field
															v-model="editedItem.datos_entidad.datos.depto"
															label="Depto"
															:rules="[$rulesMax6]"
															:disabled="entidadDisabled"
														></v-text-field>
													</v-col>
													<v-col cols="12" md="4" sm="12" class="py-0">
														<v-text-field
															v-model="editedItem.datos_entidad.datos.cp"
															label="Código Postal"
															:rules="[
																$rulesEnterosPositivos,
																$rulesNumericoMax10,
																$rulesRequerido,
															]"
															:disabled="entidadDisabled"
														></v-text-field>
													</v-col>
												</v-row>
											</v-col>
											<v-col cols="12" class="text-right">
												<v-btn
													color="primary"
													outlined
													class="mr-2"
													@click="entidadEstadoEdicion"
												>
													<v-icon class="px-1">{{
														entidadBtnEdicionImg
													}}</v-icon>
													{{ entidadBtnEdicion }}</v-btn
												>
												<v-btn
													color="primary"
													class="mr-0"
													:disabled="entidadDisabled"
													@click="actualizarDatosEntidad"
												>
													Guardar</v-btn
												>
											</v-col>
										</v-row>
									</v-card-text>
								</v-col>
							</v-row>
						</v-form>
					</v-card-text>
					<v-card-actions>

						<v-spacer></v-spacer>
						<v-btn  color="white" @click="cancel" class="capitalize-first custom-btn">
							Cancelar
						</v-btn>
						<v-btn  color="primary" @click="changePassword()" class="capitalize-first custom-btn">
							Cambiar Contraseña
						</v-btn>
						<!--
						<v-btn class="mr-2 btn-primary2" @click="cancel()">
								<v-icon class="px-1">mdi-arrow-left</v-icon>
								Cancelar</v-btn
							>
						-->

						</v-card-actions>
						<!--
					<v-row>
						<v-col cols="12" class="text-right">
							<v-btn class="mr-2 btn-primary2" @click="$goBack()">
								<v-icon class="px-1">mdi-arrow-left</v-icon>
								Cambiar Contraseña</v-btn
							>
						</v-col>
						<v-col cols="12" class="text-right">
							<v-btn class="mr-2 btn-primary2" @click="$goBack()">
								<v-icon class="px-1">mdi-arrow-left</v-icon>
								Volver</v-btn
							>
						</v-col>
					</v-row>
					-->
					<!--</base-material-card>-->
				</v-card>
			</v-col>
		</v-row>
		<v-snackbar
			v-model="snackbar"
			:bottom="true"
			:color="color"
			:timeout="timeout"
		>
			{{ text }}

			<template v-slot:action="{ attrs }">
				<v-btn dark text v-bind="attrs" @click="snackbar = false">
					Cerrar
				</v-btn>
			</template>
		</v-snackbar>
	</v-container>
</template>

<script>
	export default {
		name: "UserProfile",

		data: (vm) => ({
			valid: true,
			route: "usuarios",
			dialog: false,
			snackbar: false,
			visible: true,
			text: "Registro Insertado",
			color: "success",
			timeout: 4000,
			editedIndex: -1,
			avatarPreview: "/img/acara_logo_2.png",

			editedItem: {
				datos_usuario: {
					firstName: "",
					attributes: {
						telefono: "",
						tipo_documento: "",
						celular: "",
						documento: "",
						imagen_perfil: "",
					},
				},
				datos_entidad: {
					cuit: "",
					nombre: "",
					datos: {},
				},
			},

			provincias: [],
			partidos: [],
			ciudades: [],

			identifiertypes: [],

			userDisabled: true,
			userBtnEdicion: "Habilitar Edición",
			userBtnEdicionImg: "mdi-eye",
			entidadDisabled: true,
			entidadBtnEdicion: "Habilitar Edición",
			entidadBtnEdicionImg: "mdi-eye",
			avatarSrc: "",
		}),

		computed: {},

		mounted() {
			//this.editedItem.datos_entidad.cuit = "123123";

			this.getDatosUsuarios();
			this.getDatosEntidad();

			console.log("Componente Mi Perfil creado");
			this.$getData(["identifiertypes", "provincias"]);

			this.fetchImageProfile();
			//
		},

		created() {
			//this.editedItem = this.$store.state.user.userInfo;
			//this.$cv("colorPrimary");
		},

		methods: {
			async fetchImageProfile() {
				if (
					this.$store.state.user.userInfo.attributes.imagen_perfil &&
					this.$store.state.user.userInfo.attributes.imagen_perfil != ""
				) {
					await this.$axiosApi
						.get("viewimageprofile")
						.then((response) => {
							const data = response.data;
							if (data.fileContent) {
								// Decodificar el contenido base64 y crear un blob
								const binaryString = window.atob(data.fileContent);
								const binaryLen = binaryString.length;
								const bytes = new Uint8Array(binaryLen);
								for (let i = 0; i < binaryLen; i++) {
									bytes[i] = binaryString.charCodeAt(i);
								}
								const blob = new Blob([bytes], { type: "image/jpeg" }); // Ajusta el tipo MIME según el tipo de imagen

								this.avatarSrc = window.URL.createObjectURL(blob);
							} else {
								console.error("No se pudo obtener el archivo.");
								this.avatarSrc = this.avatarPreview; // Usar imagen de previa en caso de error
							}
						})
						.catch((error) => {
							console.log(error);
							this.avatarSrc = this.avatarPreview; // Usar imagen de previa en caso de error
						});
				} else {
					this.avatarSrc = this.avatarPreview;
				}
			},

			triggerFileInput() {
				this.$refs.imageUploadInput.click();
			},
			async handleImageUpload(event) {
				const file = event.target.files[0];
				const maxFileSize = 2 * 1024 * 1024; // 2 MB en bytes

				if (file) {
					if (file.size > maxFileSize) {
						this.snackbar = true;
						this.text = "El tamaño del archivo debe ser menor o igual a 2 MB.";
						this.color = "error";
						return;
					}
					var fd = new FormData();

					fd.append("userId", this.$store.state.user.id);
					let i = 0;

					fd.append("file", file, file.name);

					await this.$axiosApi
						.postFile("uploadimageprofile", fd)
						.then((r) => {
							this.editedItem.datos_usuario.attributes.imagen_perfil = file.name;

							this.actualizarDatosUsuarios(true);
							this.fetchImageProfile();
						})
						.catch((e) => {
							this.snackbar = true;
							this.text = "Error al ingresar los datos. Error: " + e.message;
							this.color = "error";
							Swal.close();
						});
				}
			},
			estadoEdicion() {
				if (!this.userDisabled) {
					Swal.fire({
						title: " Desea cancelar la edición de los datos?",
						html: "Si cancela la operación los datos editados no se guardarán",
						icon: "question",

						showCancelButton: true,

						confirmButtonColor: "#3085d6",
						cancelButtonColor: "#d33",
						confirmButtonText: "Aceptar",
						cancelButtonText: "Cancelar",
						showLoaderOnConfirm: true,
						allowOutsideClick: () => !Swal.isLoading(),
					}).then((result) => {
						if (result.isConfirmed) {
							this.getDatosUsuarios();
							this.userDisabled = true;

							this.userBtnEdicion = "Habilitar Edición";

							this.userBtnEdicionImg = "mdi-eye";


						} else {
						}
					});
				} else {
					this.userDisabled = false;

					this.userBtnEdicion = "Cancelar";

					this.userBtnEdicionImg = "mdi-close";

					
			

				}
			},
			entidadEstadoEdicion() {
				if (!this.entidadDisabled) {
					Swal.fire({
						title: " Desea cancelar la edición de los datos?",
						html: "Si cancela la operación los datos editados no se guardarán",
						icon: "question",

						showCancelButton: true,

						confirmButtonColor: "#3085d6",
						cancelButtonColor: "#d33",
						confirmButtonText: "Aceptar",
						cancelButtonText: "Cancelar",
						showLoaderOnConfirm: true,
						allowOutsideClick: () => !Swal.isLoading(),
					}).then((result) => {
						if (result.isConfirmed) {

							this.getDatosEntidad();

							this.entidadDisabled = true;

							this.entidadBtnEdicion = "Habilitar Edición";

							this.entidadBtnEdicionImg = "mdi-eye";
						} else {
						}
					});
				} else {
					this.entidadDisabled = false;

					this.entidadBtnEdicion = "Cancelar";

					this.entidadBtnEdicionImg = "mdi-close";
				}
			},
			async actualizarDatosUsuarios(cambio_imagen = false) {
				
				Swal.fire({
						title: "Esta seguro que desea guardar los datos?",
						html: "Si confirma la operación los datos editados se guardarán",
						icon: "question",

						showCancelButton: true,

						confirmButtonColor: "#3085d6",
						cancelButtonColor: "#d33",
						confirmButtonText: "Aceptar",
						cancelButtonText: "Cancelar",
						showLoaderOnConfirm: true,
						allowOutsideClick: () => !Swal.isLoading(),
					}).then((result) => {
						if (result.isConfirmed) {
 
							Swal.alertGetInfo("Actualizando información");

							this.$axiosApi
							.put(
								"updatedatosusuario",
								this.editedItem.datos_usuario.id,
								this.editedItem.datos_usuario
							)
							.then((r) => {
								if (r.data.data == null && r.data.code == 200) {
									Swal.close();

									this.snackbar = true;
									this.text = "Registro Actualizado";
									this.color = "success";
									//this.$store.state.user.userInfo = this.editedItem;
									this.$store.commit("SET_USER", this.$keycloak);


									if (!cambio_imagen) 
									{
										this.userBtnEdicion = "Habilitar Edición";

										this.userBtnEdicionImg = "mdi-eye";
										
										this.userDisabled = true;
									}
								}
							})
							.catch((e) => {
								this.snackbar = true;
								this.text = "Error al ingresar los datos. Error: " + e.message;
								this.color = "error";
								Swal.close();
							});

 
						} else {
						}
					});


						
			},

			async actualizarDatosEntidad() {


				Swal.fire({
						title: "Esta seguro que desea guardar los datos?",
						html: "Si confirma la operación los datos editados se guardarán",
						icon: "question",

						showCancelButton: true,

						confirmButtonColor: "#3085d6",
						cancelButtonColor: "#d33",
						confirmButtonText: "Aceptar",
						cancelButtonText: "Cancelar",
						showLoaderOnConfirm: true,
						allowOutsideClick: () => !Swal.isLoading(),
					}).then((result) => {
						if (result.isConfirmed) {
							Swal.alertGetInfo("Actualizando información");
							let entidadId = this.$store.state.user.entidades[0];
							 this.$axiosApi
								.put("entidades", entidadId, this.editedItem.datos_entidad)
								.then((r) => {
									if (r.data.code == 200) {
										Swal.close();
										this.snackbar = true;
										this.text = "Registro Actualizado";
										this.color = "success";
										//this.entidadEstadoEdicion();
			
										this.entidadDisabled = true;

										this.entidadBtnEdicion = "Habilitar Edición";

										this.entidadBtnEdicionImg = "mdi-eye";
									}
								})
								.catch((e) => {
									this.snackbar = true;
									this.text = "Error al ingresar los datos. Error: " + e.message;
									this.color = "error";
									Swal.close();
								});
							 
 
						} else {
						}
					});



				
			},

			async getPartidos(provincia_id, con_alerta = false) {
				if (con_alerta) Swal.alertGetInfo("Obteniendo Partidos <br><b></b>");
				this.partidos = [];
				let url = "api/provincias/public/" + provincia_id + "/partidos";

				this.$axiosApi
					.sendRequest(url, "get")
					.then((r) => {
						if (r.data.data) {
							console.log("partidos");
							console.log(r.data.data);
							this.partidos = r.data.data;
							if (con_alerta) Swal.close();
						}
					})
					.catch(function (error) {
						if (con_alerta) Swal.close();
						console.log(error);
					});
			},
			async getCiudades(partido_id, con_alerta = true) {
				if (con_alerta) Swal.alertGetInfo("Obteniendo Ciudades <br><b></b>");
				this.ciudades = [];
				let url = "api/partidos/public/" + partido_id + "/localidades";

				this.$axiosApi
					.sendRequest(url, "get")
					.then((r) => {
						if (r.data.data) {
							console.log("ciudades");
							console.log(r.data.data);
							this.ciudades = r.data.data;
							if (con_alerta) Swal.close();
						}
					})
					.catch(function (error) {
						if (con_alerta) Swal.close();
						console.log(error);
					});
			},

			async getDatosUsuarios() {
				//this.editedItem = Object.assign({}, this.$store.state.user.userInfo);
				Swal.alertGetInfo("Obteniendo Datos del usuario <br><b></b>");
				let userId = this.$store.state.user.id;
				await this.$axiosApi
					.getByCriteria("usuarios", userId)
					.then((r) => {
						if (r.data.data) {
							this.editedItem.datos_usuario = r.data.data;

							Swal.close();
						}
					})
					.catch(function (error) {
						Swal.close();
						console.log(error);
					});
			},

			async getDatosEntidad() {
				let entidadId = this.$store.state.user.entidades[0];
				await this.$axiosApi
					.getByCriteria("entidades", entidadId)
					.then((r) => {
						if (r.data.data) {
							console.log("datos entidad");
							console.log(r.data.data);
							this.editedItem.datos_entidad = r.data.data;
							if (this.editedItem.datos_entidad.datos.provincia.id)
								this.getPartidos(
									this.editedItem.datos_entidad.datos.provincia.id,
									false
								);
							if (this.editedItem.datos_entidad.datos.partido.id)
								this.getCiudades(
									this.editedItem.datos_entidad.datos.partido.id,
									false
								);
							//Swal.close();
						}
					})
					.catch(function (error) {
						//Swal.close();
						console.log(error);
					});
			},
			cancel(){
				this.$router.push({path:'/'});
				//window.location.href = '/';
			}, 
			changePassword(){
				this.$router.push({path:'changePassword'});
				//window.location.href = '/';
			}, 
			
		},
	};
</script>

<style>
.custom-text-field .v-input__control .v-input__slot input {
	text-align: center;
	font-size: 24px;
	font-weight: bold;
}
</style>
.icon-size {
	width: 120px !important;
	height: 120px !important;
}
.v-card--material .v-image .v-image__image {
	border-radius: 6px;
	background-color: white;
}
</style>
